// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Timetracker2 from "../../blocks/timetracker2/src/Timetracker2";
import Matchalgorithm2 from "../../blocks/matchalgorithm2/src/Matchalgorithm2";
import Productdescription3 from "../../blocks/productdescription3/src/Productdescription3";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Customform from "../../blocks/customform/src/Customform";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Livestreaming from "../../blocks/livestreaming/src/Livestreaming";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Assessmenttest from "../../blocks/assessmenttest/src/Assessmenttest";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Leaderboard2 from "../../blocks/leaderboard2/src/Leaderboard2";
import Recommendationengine from "../../blocks/recommendationengine/src/Recommendationengine";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Waitinglist from "../../blocks/waitinglist/src/Waitinglist";
import Download from "../../blocks/download/src/Download";
import Merchantdashboard2 from "../../blocks/merchantdashboard2/src/Merchantdashboard2";
import Cfcertificategeneration2 from "../../blocks/cfcertificategeneration2/src/Cfcertificategeneration2";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Communityforum3 from "../../blocks/communityforum3/src/Communityforum3";
import Referrals from "../../blocks/referrals/src/Referrals";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Linkshare from "../../blocks/linkshare/src/Linkshare";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Securitypolicy2 from "../../blocks/securitypolicy2/src/Securitypolicy2";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import Cfapplelogin14 from "../../blocks/cfapplelogin14/src/Cfapplelogin14";
import Videolibrary2 from "../../blocks/videolibrary2/src/Videolibrary2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Automaticreminders from "../../blocks/automaticreminders/src/Automaticreminders";
import Scoring from "../../blocks/scoring/src/Scoring";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import Cfbuiltinrecorder from "../../blocks/cfbuiltinrecorder/src/Cfbuiltinrecorder";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Cvresumecandidatemanagement from "../../blocks/cvresumecandidatemanagement/src/Cvresumecandidatemanagement";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Joblisting from "../../blocks/joblisting/src/Joblisting";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
import PerformanceTrackerCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerCoursesScreen";
import PerformanceTrackerTestsForCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestsForCourseScreen";
import PerformanceTrackerStudentsOnCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentsOnCourseScreen";
import PerformanceTrackerTestScoresForStudentScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestScoresForStudentScreen";
import PerformanceTrackerBasicUserCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserCoursesScreen";
import PerformanceTrackerBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserAllCoursesScreen";
import PerformanceTrackerStudentRankingScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentRankingScreen";
import PerformanceTrackerStudentInfoScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentInfoScreen";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";



const routeMap = {
Timetracker2:{
 component:Timetracker2,
path:"/Timetracker2"},
Matchalgorithm2:{
 component:Matchalgorithm2,
path:"/Matchalgorithm2"},
Productdescription3:{
 component:Productdescription3,
path:"/Productdescription3"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Customform:{
 component:Customform,
path:"/Customform"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Livestreaming:{
 component:Livestreaming,
path:"/Livestreaming"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Assessmenttest:{
 component:Assessmenttest,
path:"/Assessmenttest"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Leaderboard2:{
 component:Leaderboard2,
path:"/Leaderboard2"},
Recommendationengine:{
 component:Recommendationengine,
path:"/Recommendationengine"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Waitinglist:{
 component:Waitinglist,
path:"/Waitinglist"},
Download:{
 component:Download,
path:"/Download"},
Merchantdashboard2:{
 component:Merchantdashboard2,
path:"/Merchantdashboard2"},
Cfcertificategeneration2:{
 component:Cfcertificategeneration2,
path:"/Cfcertificategeneration2"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Communityforum3:{
 component:Communityforum3,
path:"/Communityforum3"},
Referrals:{
 component:Referrals,
path:"/Referrals"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Linkshare:{
 component:Linkshare,
path:"/Linkshare"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Securitypolicy2:{
 component:Securitypolicy2,
path:"/Securitypolicy2"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
Cfapplelogin14:{
 component:Cfapplelogin14,
path:"/Cfapplelogin14"},
Videolibrary2:{
 component:Videolibrary2,
path:"/Videolibrary2"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Automaticreminders:{
 component:Automaticreminders,
path:"/Automaticreminders"},
Scoring:{
 component:Scoring,
path:"/Scoring"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Chat:{
 component:Chat,
path:"/Chat"},
ChatView:{
 component:ChatView,
path:"/ChatView"},
Cfbuiltinrecorder:{
 component:Cfbuiltinrecorder,
path:"/Cfbuiltinrecorder"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Cvresumecandidatemanagement:{
 component:Cvresumecandidatemanagement,
path:"/Cvresumecandidatemanagement"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Joblisting:{
 component:Joblisting,
path:"/Joblisting"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
PerformanceTrackerCoursesScreen:{
 component:PerformanceTrackerCoursesScreen,
path:"/PerformanceTrackerCoursesScreen"},
PerformanceTrackerTestsForCourseScreen:{
 component:PerformanceTrackerTestsForCourseScreen,
path:"/PerformanceTrackerTestsForCourseScreen"},
PerformanceTrackerStudentsOnCourseScreen:{
 component:PerformanceTrackerStudentsOnCourseScreen,
path:"/PerformanceTrackerStudentsOnCourseScreen"},
PerformanceTrackerTestScoresForStudentScreen:{
 component:PerformanceTrackerTestScoresForStudentScreen,
path:"/PerformanceTrackerTestScoresForStudentScreen"},
PerformanceTrackerBasicUserCoursesScreen:{
 component:PerformanceTrackerBasicUserCoursesScreen,
path:"/PerformanceTrackerBasicUserCoursesScreen"},
PerformanceTrackerBasicUserAllCoursesScreen:{
 component:PerformanceTrackerBasicUserAllCoursesScreen,
path:"/PerformanceTrackerBasicUserAllCoursesScreen"},
PerformanceTrackerStudentRankingScreen:{
 component:PerformanceTrackerStudentRankingScreen,
path:"/PerformanceTrackerStudentRankingScreen"},
PerformanceTrackerStudentInfoScreen:{
 component:PerformanceTrackerStudentInfoScreen,
path:"/PerformanceTrackerStudentInfoScreen"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},

  Home: {
component:Scheduling,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
